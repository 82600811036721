import { useMantineTheme } from '@mantine/core';
import { CaretLeft } from '@phosphor-icons/react';
import { PebbleButtonsEnum } from 'enums';
import { PebbleButtonSet } from 'components/common';

interface BackButtonProps {
  onClick?: () => void;
  label?: string;
  href?: string;
  component?: 'a';
  disabled?: boolean;
  className?: string;
}

const BackButton: React.FC<BackButtonProps> = ({
  onClick,
  label,
  href,
  component,
  disabled,
  className,
}) => {
  const theme = useMantineTheme();
  return (
    <PebbleButtonSet
      btnVariant={PebbleButtonsEnum.TERTIARY}
      size="md"
      leftSection={<CaretLeft size={22} color={theme.colors.blue[6]} weight="bold" />}
      onClick={onClick}
      pl={0}
      component={component}
      href={href}
      disabled={disabled}
      className={className}
    >
      {label || 'Back'}
    </PebbleButtonSet>
  );
};

export default BackButton;
