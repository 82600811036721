import { ReactNode } from 'react';
import { NextPage } from 'next';
import { useMantineTheme } from '@mantine/core';

interface ErrorPageProps {
  children: ReactNode;
}

export const ErrorPage: NextPage<ErrorPageProps> = ({ children }) => {
  const theme = useMantineTheme();
  return (
    <div
      style={{
        background: `${theme.colors.blue[8]} url('/backgroundPattern.png') no-repeat`,
        backgroundSize: 'cover',
        color: 'white',
        textAlign: 'center',
        height: '100vh',
      }}
    >
      {children}
    </div>
  );
};
