import { Anchor, Card, Divider, Flex, Stack, Text } from '@mantine/core';
import classes from './CheckboxFilter.module.scss';
import classNames from 'classnames';
import { Fragment } from 'react';
import { PebbleCheckbox, PebbleCheckboxGroup } from 'components/common';
import { DynamicFilterOption } from 'interfaces';

interface CheckboxFilterProps {
  label: string;
  filters: DynamicFilterOption[];
  selectedValues: string[];
  setSelectedValues: (values: string[]) => void;
  onTrack?: () => void;
}

const CheckboxFilter: React.FC<CheckboxFilterProps> = ({
  label,
  filters,
  selectedValues,
  setSelectedValues,
  onTrack,
}) => {
  const isIndeterminate =
    selectedValues?.length === 0 || selectedValues?.length === filters?.length;

  if (!setSelectedValues || !selectedValues || filters?.length === 0) return null;

  return (
    <Card className={classes.filterCard}>
      <Stack>
        <Flex justify={isIndeterminate ? 'start' : 'space-between'}>
          <Text fw={700} size="18px">
            {label}
          </Text>
          {!isIndeterminate && (
            <Anchor
              component="button"
              onClick={() => {
                setSelectedValues([]);
                if (onTrack) onTrack();
              }}
              style={{
                fontSize: '16px',
                fontWeight: 700,
                color: 'var(--mantine-color-blue-6)',
                textDecoration: 'underline',
              }}
            >
              Select all
            </Anchor>
          )}
        </Flex>
        <PebbleCheckboxGroup value={selectedValues} onChange={setSelectedValues}>
          {filters.map((option) => (
            <Fragment key={option.value}>
              <PebbleCheckbox
                value={option.value || option.label}
                label={option.label}
                indeterminate={isIndeterminate}
                classNames={{
                  root: classes.checkboxRoot,
                  icon: classNames(classes.checkboxIcon, {
                    [classes.checkboxIconChecked]: !isIndeterminate,
                  }),
                }}
                onClick={(event) => {
                  if (event.currentTarget.checked && onTrack) {
                    onTrack();
                  }
                }}
                fullWidth
              />
              <Divider c="#F1F1F2" />
            </Fragment>
          ))}
        </PebbleCheckboxGroup>
      </Stack>
    </Card>
  );
};

export default CheckboxFilter;
