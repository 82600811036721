import { gql } from '@apollo/client';

const changeBlockTrial = gql`
  mutation changeBlockTrial($input: ChangeBlockTrialsDatesInput!) {
    changeBlockTrialDates(input: $input) {
      id
    }
  }
`;

export default changeBlockTrial;
