import { gql } from '@apollo/client';

export const getScheduledActivities = gql`
  query activitiesBySupplier(
    $supplierId: UUID!
    $dateFilter: TermDateFilterEnum
    $offset: Int
    $limit: Int
    $activityId: UUID
    $venue: String
    $sortBy: String
    $activityTags: [String]
    $activityCategories: [UUID]
  ) {
    activitiesBySupplier(
      supplierId: $supplierId
      dateFilter: $dateFilter
      offset: $offset
      limit: $limit
      activityId: $activityId
      venue: $venue
      sortBy: $sortBy
      activityTags: $activityTags
      activityCategories: $activityCategories
    ) {
      total
      filters {
        activities {
          id
          name
        }
        venues {
          name
          value
        }
        activityTags {
          name
          value
        }
        categories {
          id
          name
        }
      }
      activities {
        id
        slug
        status
        createdAt
        location {
          addressLine1
          addressLine2
          city
          country
          postCode
        }
        locationName
        locationAdditionalInfo
        name
        bookingType
        activityType
        blockSubtype
        minDate
        maxDate
        weekdays
        ageRange
        sessions {
          id
        }
        classes {
          id
          name
        }
        schedules {
          id
          startTime
          endTime
          dayOfTheWeek
        }
        times {
          startTime
          endTime
        }
        categories {
          id
          name
          slug
        }
        hasBookings
        isOnline
        bookingsInformation {
          totalBookings
          totalCapacity
          description
        }
        waitlistRequestCount
        enableRegisterCheckinCheckoutMode
      }
    }
  }
`;
