import { gql } from '@apollo/client';

const GetSupplierFinancialReportByDay = gql`
  query GetSupplierFinancialReportByDay(
    $supplierId: UUID!
    $transactionDateTo: Date!
    $transactionDateFrom: Date!
  ) {
    getSupplierFinancialReportByDay(
      supplierId: $supplierId
      transactionDateTo: $transactionDateTo
      transactionDateFrom: $transactionDateFrom
    ) {
      transactionDate
      netPaymentAmount
      netTransferAmount
      netPebbleFee
    }
  }
`;

export default GetSupplierFinancialReportByDay;
