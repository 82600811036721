import { Flex, Text, useMantineTheme } from '@mantine/core';
import { InviteAttendeeTabsEnum, PebbleButtonsEnum } from 'enums';
import classes from './ReviewCustomersScreen.module.scss';
import { useMediaQuery } from '@mantine/hooks';
import EmailAddition from '../EmailAddition/EmailAddition';
import { handleEmailsValidation } from '../EmailAddition/EmailAddition.utils';
import { useState } from 'react';
import CustomerSummary from '../CustomerSummary/CustomerSummary';
import { Actions, trackAction } from 'utils/amplitude';
import { Info } from '@phosphor-icons/react';
import { PebbleButtonSet } from 'components/common';
import BackButton from 'components/BackButton/BackButton';

interface IReviewCustomersScreenProps {
  setActiveTab(val: InviteAttendeeTabsEnum): void;
  emailInputs: string[];
  setEmailInputs(val: string[]): void;
  emailErrors: { [index: number]: string } | null;
  setEmailErrors(val: { [index: number]: string } | null): void;
  supplierId: string;
  token: string;
  selectedActivitiesId: string[];
  customerEmails: string[];
  setCustomerEmails(val: string[]): void;
}

const ReviewCustomersScreen: React.FC<IReviewCustomersScreenProps> = ({
  setActiveTab,
  emailInputs,
  setEmailInputs,
  emailErrors,
  setEmailErrors,
  supplierId,
  token,
  selectedActivitiesId,
  customerEmails,
  setCustomerEmails,
}: IReviewCustomersScreenProps) => {
  const theme = useMantineTheme();

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);
  const [manuallyAddCustomers, setManuallyAddCustomers] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  return (
    <div className={classes.wrapper}>
      <BackButton
        className={classes.backButton}
        onClick={() => {
          setActiveTab(InviteAttendeeTabsEnum.PREVIOUS_ACTIVITIES_OR_MANUAL);
          trackAction(Actions.ENROL_BACK);
        }}
      />
      <Text className={classes.title}>Review customers</Text>
      <Text className={classes.copy}>
        Review the customers you will send this invite to. You can <strong>add</strong> and{' '}
        <strong>remove</strong> individuals below.
      </Text>

      <Flex align={isMobile ? 'start' : 'center'} className={classes.warningWrapper}>
        <Info size={isMobile ? 30 : 24} color={theme.colors.blue[8]} />
        <Text className={classes.warningCopy}>
          Only customers who have opted into Pebble Marketing can be sent invites.
        </Text>
      </Flex>

      <CustomerSummary
        supplierId={supplierId}
        token={token}
        selectedActivitiesId={selectedActivitiesId}
        setCustomerEmails={setCustomerEmails}
      />

      {(manuallyAddCustomers || emailInputs[0] !== '') && (
        <EmailAddition
          emailInputs={emailInputs}
          setEmailInputs={setEmailInputs}
          emailErrors={emailErrors}
          setEmailErrors={setEmailErrors}
        />
      )}

      {!manuallyAddCustomers && (
        <>
          <PebbleButtonSet
            btnVariant={PebbleButtonsEnum.SECONDARY}
            size="md"
            onClick={() => {
              setManuallyAddCustomers(true);
              trackAction(Actions.ENROL_ADDITIONAL_CUSTOMERS);
            }}
            fullWidth
            mb="xl"
          >
            Add additional customer(s)
          </PebbleButtonSet>
        </>
      )}
      <PebbleButtonSet
        btnVariant={PebbleButtonsEnum.PRIMARY}
        size="md"
        fullWidth
        onClick={() => {
          if (manuallyAddCustomers && emailInputs[0] !== '') {
            handleEmailsValidation(
              emailInputs,
              setEmailErrors,
              setActiveTab,
              InviteAttendeeTabsEnum.INVITE_MESSAGE,
            );
          } else if (emailInputs[0] === '' && customerEmails.length === 0) {
            setShowErrorMessage(true);
            return;
          } else {
            setActiveTab(InviteAttendeeTabsEnum.INVITE_MESSAGE);
            trackAction(Actions.ENROL_NEXT);
          }
        }}
      >
        Next
      </PebbleButtonSet>
      {showErrorMessage && (
        <Text component="p" color="red" size="xs" mt={'xs'}>
          You must add at least one email
        </Text>
      )}
    </div>
  );
};

export default ReviewCustomersScreen;
