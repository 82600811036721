import { FC } from 'react';
import { BookingsSortByEnum, CustomersSortByEnum, ScheduledActivitySortByEnum } from 'enums';
import classes from './TableHeader.module.scss';
import classNames from 'classnames';
import { Table, useMantineTheme } from '@mantine/core';
import { CaretDown } from '@phosphor-icons/react';

export type TableHeaderProps = {
  label: string;
  align?: 'left' | 'center' | 'right';
  ascKey: BookingsSortByEnum | CustomersSortByEnum | ScheduledActivitySortByEnum;
  descKey: BookingsSortByEnum | CustomersSortByEnum | ScheduledActivitySortByEnum;
  activeSortKey?: BookingsSortByEnum | CustomersSortByEnum | ScheduledActivitySortByEnum;
  onClick?: (key: string) => void;
};

export const TableHeader: FC<TableHeaderProps> = (props) => {
  const theme = useMantineTheme();
  const { label, ascKey, descKey, activeSortKey, onClick, align = 'left' } = props;

  const sortKey = activeSortKey === descKey ? ascKey : descKey;

  return (
    <Table.Th
      className={classNames(classes.header, {
        [classes.headerActive]: activeSortKey === ascKey || activeSortKey === descKey,
        [classes.leftAlign]: align === 'left',
        [classes.centerAlign]: align === 'center',
        [classes.rightAlign]: align === 'right',
      })}
      onClick={() => {
        if (onClick && sortKey) {
          onClick(sortKey);
        }
      }}
    >
      {label}

      <CaretDown
        weight="fill"
        size={10}
        className={classNames(classes.icon, {
          [classes.iconActive]: activeSortKey === descKey,
        })}
        color={theme.colors.gray[5]}
      />
    </Table.Th>
  );
};
