import { FC, useEffect, useState } from 'react';
import { Button, Group, Drawer, Flex, useMantineTheme, Box } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useClickOutside, useMediaQuery, UseMediaQueryOptions } from '@mantine/hooks';
import classes from './DateFilter.module.scss';
import { DateFilters } from 'utils/bookingsFilters/filters';
import { ClearAllButton } from 'components/ClearAllButton';
import { Actions, trackAction } from 'utils/amplitude';
import classNames from 'classnames';
import dayjs from 'dayjs';

export interface IDateFilter {
  activeFilter: DateFilters | null;
  activeCustomDateFilter: [Date | null, Date | null];
  onDateFilterChange(filter: DateFilters): void;
  onCustomDateChange(dates: [Date | null, Date | null]): void;
  open: boolean;
  onClose(): void;
  onClearFilters?(): void;
  isHomePage?: boolean;
  style?: object;
}

export const DateFilter: FC<IDateFilter> = ({
  activeFilter,
  onDateFilterChange,
  onCustomDateChange,
  open,
  onClose,
  activeCustomDateFilter,
  onClearFilters,
  isHomePage = false,
  style = {},
}) => {
  const theme = useMantineTheme();

  const [customValue, setCustomValue] =
    useState<[Date | null, Date | null]>(activeCustomDateFilter);

  const ref = useClickOutside(onClose);

  const options: UseMediaQueryOptions = {
    getInitialValueInEffect: false,
  };

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true, options);

  useEffect(() => {
    setCustomValue(activeCustomDateFilter);
  }, [activeCustomDateFilter]);

  const content = (
    <Box style={{ ...style }}>
      <Group className={classes.buttons} wrap="nowrap">
        <Button
          data-testid="All dates"
          radius="lg"
          className={classNames(classes.button, {
            [classes.activeButton]: activeFilter === DateFilters['All dates'],
          })}
          onClick={() => {
            if (isHomePage) trackAction(Actions.DASH_DATE_PICKER);
            setCustomValue([null, null]);
            onDateFilterChange(DateFilters['All dates']);
            onClose();
          }}
        >
          All dates
        </Button>
        <Button
          data-testid="Upcoming"
          radius="lg"
          className={classNames(classes.button, {
            [classes.activeButton]: activeFilter === DateFilters.Upcoming,
          })}
          onClick={() => {
            if (isHomePage) trackAction(Actions.DASH_DATE_PICKER);
            setCustomValue([null, null]);
            onDateFilterChange(DateFilters.Upcoming);
            onClose();
          }}
        >
          Upcoming
        </Button>
        <Button
          data-testid="Past"
          radius="lg"
          className={classNames(classes.button, {
            [classes.activeButton]: activeFilter === DateFilters.Past,
          })}
          onClick={() => {
            if (isHomePage) trackAction(Actions.DASH_DATE_PICKER);
            setCustomValue([null, null]);
            onDateFilterChange(DateFilters.Past);
            onClose();
          }}
        >
          Past
        </Button>
      </Group>

      <Flex direction="row" justify="center" className={classes.calendarWrapper}>
        <DatePicker
          title="Custom date selector"
          type="range"
          value={customValue}
          onChange={(val) => {
            if (isHomePage) trackAction(Actions.DASH_DATE_PICKER);
            const formattedUTCValues = val.map((value) => {
              if (value === null) {
                return null;
              } else {
                return dayjs(value).utc(true).toDate();
              }
            }) as [Date | null, Date | null];
            setCustomValue(formattedUTCValues);
          }}
          maxLevel="month"
          size="md"
          defaultDate={customValue[0] || undefined}
          classNames={{
            calendarHeaderLevel: classes.calendarHeader,
            calendarHeaderControl: classes.calendarControl,
            day: classes.dayCell,
          }}
        />
      </Flex>
      <Button
        className={classes.viewButton}
        disabled={!customValue[0]}
        fullWidth
        onClick={() => {
          onCustomDateChange(customValue);
          onClose();
        }}
      >
        View
      </Button>
    </Box>
  );

  return (
    <>
      {isMobile ? (
        <Drawer
          opened={open}
          onClose={onClose}
          position="bottom"
          size="85%"
          classNames={{
            root: classes.drawer,
            header: classes.drawerHeader,
            close: classes.drawerCloseButton,
          }}
        >
          {onClearFilters && (
            <ClearAllButton onClick={onClearFilters} disabled={customValue && !customValue?.[0]} />
          )}
          {content}
        </Drawer>
      ) : (
        open && (
          <div className={isHomePage ? classes.homePageReport : classes.floating} ref={ref}>
            {content}
          </div>
        )
      )}
    </>
  );
};
