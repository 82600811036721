import { Box, Stack, Text } from '@mantine/core';
import classes from './MainFilterScreen.module.scss';
import classNames from 'classnames';

interface IMainFilterScreen {
  setCurrentInnerModalComponent(val: string | null): void;
  selectedActivityLabel: string;
  selectedVenueLabel: string;
  selectedTimesLabel: string | null;
  selectedWeekdayLabel: string | null;
  selectedTagsLabel: string | null;
  showDaysFilter?: boolean;
  scheduledActivities?: boolean;
  selectedCategoryLabel: string;
  selectedCategoryFilters?: string[];
}

const MainFilterScreen: React.FC<IMainFilterScreen> = ({
  setCurrentInnerModalComponent,
  selectedActivityLabel,
  selectedVenueLabel,
  selectedTimesLabel,
  selectedWeekdayLabel,
  selectedTagsLabel,
  showDaysFilter,
  scheduledActivities,
  selectedCategoryLabel,
  selectedCategoryFilters,
}) => {
  const hasActivityLabel = selectedActivityLabel !== 'All activities';
  const hasVenueLabel = selectedVenueLabel !== 'All venues';
  const hasTimesLabel = selectedTimesLabel !== 'All times';
  const hasWeekdayFilter = selectedWeekdayLabel !== 'All days';
  const hasActivityTagsLabel = selectedTagsLabel && selectedTagsLabel.length > 0;
  const hasCategoriesLabel = selectedCategoryFilters && selectedCategoryFilters.length > 0;

  return (
    <Stack w="100%" justify="space-evenly" mb={20}>
      <Box
        mt={0}
        className={classNames(classes.card, {
          [classes.additionalPadding]: !hasActivityLabel,
          [classes.cardWithFiltersApplied]: hasActivityLabel,
        })}
        onClick={() => {
          setCurrentInnerModalComponent('activities');
        }}
        role="menuitem"
      >
        <Stack gap={0}>
          <Text className={classes.cardLeftSection}>Activities</Text>
          {hasActivityLabel && (
            <Text className={classes.scheduleFilterValue} pt={hasActivityLabel ? 'sm' : 0}>
              {selectedActivityLabel}
            </Text>
          )}
        </Stack>
        <Text
          className={classNames(classes.cardRightSection, {
            [classes.update]: hasActivityLabel,
          })}
        >
          {!hasActivityLabel ? 'All activities' : 'Update'}
        </Text>
      </Box>

      {selectedTimesLabel && (
        <Box
          mt={0}
          className={classNames(classes.card, {
            [classes.additionalPadding]: !hasTimesLabel,
            [classes.cardWithFiltersApplied]: hasTimesLabel,
          })}
          onClick={() => {
            setCurrentInnerModalComponent('times');
          }}
          role="menuitem"
        >
          <Stack gap={0}>
            <Text className={classes.cardLeftSection}>Times</Text>
            {hasTimesLabel && (
              <Text className={classes.scheduleFilterValue} pt={hasTimesLabel ? 'sm' : 0}>
                {selectedTimesLabel}
              </Text>
            )}
          </Stack>
          <Text
            className={classNames(classes.cardRightSection, {
              [classes.update]: hasTimesLabel,
            })}
          >
            {!hasTimesLabel ? 'All sessions' : 'Update'}
          </Text>
        </Box>
      )}

      {selectedWeekdayLabel && showDaysFilter && (
        <Box
          mt={0}
          className={classNames(classes.card, {
            [classes.additionalPadding]: !hasWeekdayFilter,
            [classes.cardWithFiltersApplied]: hasWeekdayFilter,
          })}
          onClick={() => {
            setCurrentInnerModalComponent('days');
          }}
          role="menuitem"
        >
          <Stack gap={0}>
            <Text className={classes.cardLeftSection}>Days</Text>
            {hasWeekdayFilter && (
              <Text className={classes.scheduleFilterValue} pt={hasWeekdayFilter ? 'sm' : 0}>
                {selectedWeekdayLabel}
              </Text>
            )}
          </Stack>
          <Text
            className={classNames(classes.cardRightSection, {
              [classes.update]: hasWeekdayFilter,
            })}
          >
            {!hasWeekdayFilter ? 'All days' : 'Update'}
          </Text>
        </Box>
      )}

      <Box
        mt={0}
        className={classNames(classes.card, {
          [classes.additionalPadding]: !hasVenueLabel,
          [classes.cardWithFiltersApplied]: hasVenueLabel,
        })}
        onClick={() => {
          setCurrentInnerModalComponent('venues');
        }}
        role="menuitem"
      >
        <Stack gap={0}>
          <Text className={classes.cardLeftSection}>Venues</Text>
          {hasVenueLabel && (
            <Text className={classes.scheduleFilterValue} pt={hasVenueLabel ? 'sm' : 0}>
              {selectedVenueLabel}
            </Text>
          )}
        </Stack>
        <Text
          className={classNames(classes.cardRightSection, {
            [classes.update]: hasVenueLabel,
          })}
        >
          {!hasVenueLabel ? 'All venues' : 'Update'}
        </Text>
      </Box>

      {scheduledActivities && (
        <Stack>
          <Box
            mt={0}
            className={classNames(classes.card, {
              [classes.additionalPadding]: !hasActivityTagsLabel,
              [classes.cardWithFiltersApplied]: hasActivityTagsLabel,
            })}
            onClick={() => {
              setCurrentInnerModalComponent('activityTags');
            }}
            role="menuitem"
          >
            <Stack gap={0}>
              <Text className={classes.cardLeftSection}>Activity Tags</Text>
              {hasActivityTagsLabel && (
                <Text className={classes.scheduleFilterValue} pt={!hasActivityTagsLabel ? 'sm' : 0}>
                  {selectedTagsLabel}
                </Text>
              )}
            </Stack>
            <Text className={classNames(classes.cardRightSection, classes.update)}>Update</Text>
          </Box>
          <Box
            mt={0}
            className={classNames(classes.card, {
              [classes.additionalPadding]: !hasCategoriesLabel,
              [classes.cardWithFiltersApplied]: hasCategoriesLabel,
            })}
            onClick={() => {
              setCurrentInnerModalComponent('activityCategories');
            }}
            role="menuitem"
          >
            <Stack gap={0}>
              <Text className={classes.cardLeftSection}>Categories</Text>
              {hasCategoriesLabel && (
                <Text className={classes.scheduleFilterValue} pt={!hasCategoriesLabel ? 'sm' : 0}>
                  {selectedCategoryLabel}
                </Text>
              )}
            </Stack>
            <Text className={classNames(classes.cardRightSection, classes.update)}>Update</Text>
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

export default MainFilterScreen;
