import { gql } from '@apollo/client';

const GetSupplierFinancialReportByMonth = gql`
  query GetSupplierFinancialReportByMonth(
    $supplierId: UUID!
    $transactionDateTo: Date!
    $transactionDateFrom: Date!
  ) {
    getSupplierFinancialReportByMonth(
      supplierId: $supplierId
      transactionDateTo: $transactionDateTo
      transactionDateFrom: $transactionDateFrom
    ) {
      transactionDate
      paymentAmount
      refundAmount
      manualInvoicePaymentAmount
      pebbleFee
      vatPebbleFee
      netTransferAmount
    }
  }
`;

export default GetSupplierFinancialReportByMonth;
