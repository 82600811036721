import { gql } from '@apollo/client';

const ManualInvoicesBySupplier = gql`
  query getManualInvoicesBySupplier(
    $supplierId: UUID!
    $offset: Int
    $limit: Int
    $searchTerm: String
    $customerEmail: String
  ) {
    getManualInvoicesBySupplier(
      supplierId: $supplierId
      offset: $offset
      limit: $limit
      searchTerm: $searchTerm
      customerEmail: $customerEmail
    ) {
      total
      manualInvoices {
        id
        customerName
        customerEmail
        invoiceNumber
        createdAt
        lastEmailSent
        invoiceDueDate
        status
        amount
        invoiceLink
        description
        items {
          itemName
          pricePerItem
          quantity
        }
        customerExists
        transferredToSupplierDate
        markedAsManuallyPaidAt
        bookingTicket {
          id
        }
        attendee {
          id
        }
      }
    }
  }
`;

export default ManualInvoicesBySupplier;
