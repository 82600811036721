import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import {
  Text,
  Box,
  Drawer,
  SegmentedControl,
  Center,
  Stack,
  ActionIcon,
  useMantineTheme,
  Grid,
  Flex,
  Loader,
} from '@mantine/core';
import classes from './FiltersDrawer.module.scss';
import {
  getLabelFromSelectedCategoryFilter,
  getLabelFromSelectedFilter,
} from 'utils/bookingsFilters/filters';
import { DynamicFilterOption } from 'interfaces';
import {
  BookingsSortByEnum,
  BookingsViewEnum,
  PebbleButtonsEnum,
  ScheduledActivitySortByEnum,
} from 'enums';
import { SortMenu } from 'components/SortMenu';
import { ClearAllButton } from 'components/ClearAllButton';
import { Actions, trackAction } from 'utils/amplitude';
import classNames from 'classnames';
import { ArrowsDownUp, SlidersHorizontal, X } from '@phosphor-icons/react';
import ActivitiesFilter from './ActivitiesFilter/ActivitiesFilter';
import VenuesFilter from './VenuesFilter/VenuesFilter';
import MainFilterScreen from './MainFilterScreen/MainFilterScreen';
import { PebbleButtonSet } from 'components/common';
import ActivityTagsFilter from './ActivityTagsFilter/ActivityTagsFilter';
import CheckboxFilter from './CheckboxFilter/CheckboxFilter';
import BackButton from 'components/BackButton/BackButton';

export interface IFiltersDrawer {
  open: boolean;
  onClose(): void;
  activityFilters: DynamicFilterOption[];
  venueFilters: DynamicFilterOption[];
  timeFilters?: DynamicFilterOption[];
  weekdayFilters?: DynamicFilterOption[];
  categoryFilters?: DynamicFilterOption[];
  tagsFilters?: DynamicFilterOption[];
  selectedActivity: string;
  onActivityChange(activity: string): void;
  selectedVenue: string;
  onVenueChange(venue: string): void;
  selectedTimes?: string[];
  setSelectedTimesFilter?(times: string[]): void;
  activeSortKey?: BookingsSortByEnum | ScheduledActivitySortByEnum;
  onSortChange?(key: BookingsSortByEnum | ScheduledActivitySortByEnum): void;
  onClearFilters(): void;
  getAppliedTimesFilterLabel?(): string;
  scheduledActivities?: boolean;
  selectedTags?: string[] | null;
  setSelectedTags?(tags: string[]): void;
  weekdayFilter?: string[];
  setWeekdayFilter?(val: string[]): void;
  getAppliedWeekdayFilterLabel?: () => string;
  bookingsView?: BookingsViewEnum;
  loading: boolean;
  selectedCategoryFilters?: string[];
  setSelectedCategoryFilters?: Dispatch<SetStateAction<string[]>>;
}

export const getMobileFilterTrackEvent = (
  subscriptionEvent: Actions,
  bookingsEvent: Actions,
  scheduledActivitiesEvent: Actions,
  view?: BookingsViewEnum,
) => {
  if (view === BookingsViewEnum.SUBSCRIPTION_BOOKINGS) {
    return trackAction(subscriptionEvent);
  } else if (view === BookingsViewEnum.STANDARD_BOOKINGS) {
    return trackAction(bookingsEvent);
  } else {
    return trackAction(scheduledActivitiesEvent);
  }
};

export const FiltersDrawer: FC<IFiltersDrawer> = ({
  open,
  onClose,
  activityFilters,
  venueFilters,
  timeFilters,
  selectedActivity,
  selectedVenue,
  selectedTimes,
  onActivityChange,
  onVenueChange,
  setSelectedTimesFilter,
  activeSortKey,
  onSortChange,
  onClearFilters,
  getAppliedTimesFilterLabel,
  scheduledActivities,
  weekdayFilter,
  selectedTags,
  setSelectedTags,
  tagsFilters,
  setWeekdayFilter,
  getAppliedWeekdayFilterLabel,
  weekdayFilters,
  bookingsView,
  loading,
  categoryFilters,
  selectedCategoryFilters,
  setSelectedCategoryFilters,
}) => {
  const theme = useMantineTheme();

  const [currentInnerModalComponent, setCurrentInnerModalComponent] = useState<string | null>(null);

  const subscriptionBooking = bookingsView === BookingsViewEnum.SUBSCRIPTION_BOOKINGS;

  const [menu, setMenu] = useState('filter');

  const formatActivityTagLabels = (tags: string[]): string => {
    selectedTags = tags.map((tag) => {
      const tagLabel = activityFilters.find((filter) => filter.value === tag)?.label;
      return tagLabel ? tagLabel : tag;
    });

    return selectedTags.join(', ');
  };

  useEffect(() => {
    if (subscriptionBooking) {
      setMenu('filter');
    }
  }, [setMenu, subscriptionBooking]);

  const selectedActivityLabel = selectedActivity
    ? getLabelFromSelectedFilter(selectedActivity, activityFilters)
    : activityFilters[0]?.label;

  const selectedVenueLabel = selectedVenue
    ? getLabelFromSelectedFilter(selectedVenue, venueFilters)
    : venueFilters[0]?.label;

  const selectedTimesLabel =
    getAppliedTimesFilterLabel && setSelectedTimesFilter && timeFilters
      ? getAppliedTimesFilterLabel()
      : null;

  const selectedWeekdayLabel =
    getAppliedWeekdayFilterLabel && setWeekdayFilter && weekdayFilter
      ? getAppliedWeekdayFilterLabel()
      : null;

  const selectedTagsLabel =
    selectedTags && selectedTags?.length > 0 ? formatActivityTagLabels(selectedTags) : null;

  const selectedCategoryLabel =
    selectedActivity && selectedCategoryFilters
      ? getLabelFromSelectedCategoryFilter(selectedCategoryFilters, categoryFilters)
      : activityFilters[0]?.label;

  const getFilterContent = () => {
    if (menu === 'filter') {
      switch (currentInnerModalComponent) {
        case 'activities':
          return (
            <ActivitiesFilter
              activityFilters={activityFilters}
              selectedActivity={selectedActivity}
              onActivityChange={onActivityChange}
              bookingsView={bookingsView}
            />
          );
        case 'venues':
          return (
            <VenuesFilter
              venueFilters={venueFilters}
              selectedVenue={selectedVenue}
              onVenueChange={onVenueChange}
              bookingsView={bookingsView}
            />
          );
        case 'times':
          return (
            timeFilters &&
            selectedTimes &&
            setSelectedTimesFilter && (
              <CheckboxFilter
                label="Times"
                filters={timeFilters}
                selectedValues={selectedTimes}
                setSelectedValues={setSelectedTimesFilter}
                onTrack={() =>
                  trackAction(
                    bookingsView === BookingsViewEnum.SUBSCRIPTION_BOOKINGS
                      ? Actions.SUB_TIME_FILTER_MOBILE
                      : Actions.BOOKING_TIME_FILTER_MOBILE,
                  )
                }
              />
            )
          );
        case 'days':
          return (
            weekdayFilters &&
            weekdayFilter &&
            setWeekdayFilter && (
              <CheckboxFilter
                label="Days"
                filters={weekdayFilters}
                selectedValues={weekdayFilter}
                setSelectedValues={setWeekdayFilter}
                onTrack={() => trackAction(Actions.SUB_DAY_FILTER_MOBILE)}
              />
            )
          );
        case 'activityTags':
          return (
            <ActivityTagsFilter
              setSelectedTags={setSelectedTags}
              selectedTags={selectedTags}
              tagsFilters={tagsFilters}
            />
          );
        case 'activityCategories':
          return (
            categoryFilters &&
            selectedCategoryFilters &&
            setSelectedCategoryFilters && (
              <CheckboxFilter
                label="Categories"
                filters={categoryFilters}
                selectedValues={selectedCategoryFilters}
                setSelectedValues={setSelectedCategoryFilters}
              />
            )
          );
        default:
          return (
            <MainFilterScreen
              setCurrentInnerModalComponent={setCurrentInnerModalComponent}
              selectedActivityLabel={selectedActivityLabel}
              selectedVenueLabel={selectedVenueLabel}
              selectedTimesLabel={selectedTimesLabel}
              selectedWeekdayLabel={selectedWeekdayLabel}
              selectedTagsLabel={selectedTagsLabel}
              showDaysFilter={subscriptionBooking}
              scheduledActivities={scheduledActivities}
              selectedCategoryLabel={selectedCategoryLabel}
              selectedCategoryFilters={selectedCategoryFilters}
            />
          );
      }
    } else if (activeSortKey && onSortChange) {
      return (
        <SortMenu
          activeSortKey={activeSortKey}
          onSortChange={onSortChange}
          ascKey={
            scheduledActivities
              ? ScheduledActivitySortByEnum.DATE_ASC
              : BookingsSortByEnum.SESSION_DATE_ASC
          }
          descKey={
            scheduledActivities
              ? ScheduledActivitySortByEnum.DATE_DESC
              : BookingsSortByEnum.SESSION_DATE_DESC
          }
        />
      );
    }
  };

  const handleClearButton = () => {
    if (menu === 'filter') {
      switch (currentInnerModalComponent) {
        case 'activities':
          return onActivityChange('All activities');
        case 'venues':
          return onVenueChange('All locations');
        case 'times':
          return setSelectedTimesFilter ? setSelectedTimesFilter([]) : null;
        case 'days':
          return setWeekdayFilter ? setWeekdayFilter([]) : null;
        case 'activityTags':
          return setSelectedTags ? setSelectedTags([]) : 'Activity tags';
        case 'activityCategories':
          return setSelectedCategoryFilters && setSelectedCategoryFilters([]);

        default:
          return onClearFilters();
      }
    } else {
      return;
    }
  };

  return (
    <Drawer
      opened={open}
      onClose={() => {
        onClose();
      }}
      position="bottom"
      classNames={{
        body: classNames(classes.standardDrawerBody, {
          [classes.drawerBody]: currentInnerModalComponent,
        }),
        header: classes.drawerHeader,
        close: classes.drawerCloseButton,
        content: classNames(classes.drawerContent, {
          [classes.innerDrawerContent]: currentInnerModalComponent,
        }),
      }}
      withCloseButton={false}
    >
      {/* Header */}
      <Stack gap={0}>
        {!currentInnerModalComponent && (
          <ActionIcon
            title="Close"
            variant="outline"
            onClick={onClose}
            className={classes.closeButton}
          >
            <X size={26} color={theme.colors.gray[6]} weight="bold" />
          </ActionIcon>
        )}
        <Grid className={classes.header} align="center">
          {currentInnerModalComponent && (
            <Grid.Col span={4}>
              <BackButton
                onClick={() => {
                  setCurrentInnerModalComponent(null);
                }}
              />
            </Grid.Col>
          )}

          {currentInnerModalComponent === null && (
            <Grid.Col span={currentInnerModalComponent ? 4 : 12}>
              <Flex w="100%" justify="space-between" align="center">
                <Text
                  fw={700}
                  size={theme.fontSizes.xl}
                  c={theme.colors.blue[8]}
                  ml="xs"
                  mt="lg"
                  mb={bookingsView === BookingsViewEnum.STANDARD_BOOKINGS ? '0' : 'sm'}
                >
                  {menu === 'filter' ? 'Filter activities' : 'Sort activities'}
                </Text>
              </Flex>
            </Grid.Col>
          )}

          <Grid.Col span={currentInnerModalComponent ? 4 : 12}>
            <Center>
              <SegmentedControl
                radius="lg"
                classNames={{
                  root: classNames(classes.controlRoot, {
                    [classes.controlRootHidden]: currentInnerModalComponent !== null,
                  }),
                  label: classes.controlLabel,
                  control: classes.control,
                }}
                transitionDuration={0}
                onChange={(value: string) => {
                  setMenu(value);
                  const action =
                    value === 'filter'
                      ? Actions.BOOKINGS_FILTERS_MOBILE
                      : Actions.BOOKINGS_SORT_MOBILE;
                  trackAction(action);
                }}
                value={menu}
                size="lg"
                data={
                  subscriptionBooking
                    ? [
                        {
                          label: (
                            <Flex align="center" mt="4px" justify="center">
                              <SlidersHorizontal size={24} color="white" weight="fill" />

                              <Text ml="xs" fw={700}>
                                FILTER
                              </Text>
                            </Flex>
                          ),
                          value: 'filter',
                        },
                      ]
                    : [
                        {
                          label: (
                            <Flex align="center" justify="center" mt="3px">
                              <SlidersHorizontal size={24} color="white" weight="fill" />
                              <Text ml="xs" fw={700}>
                                FILTER
                              </Text>
                            </Flex>
                          ),
                          value: 'filter',
                        },
                        {
                          label: (
                            <Flex align="center" mt="4px" justify="center">
                              <ArrowsDownUp size={24} />
                              <Text ml="xs" fw={700}>
                                SORT
                              </Text>
                            </Flex>
                          ),
                          value: 'sort',
                        },
                      ]
                }
              />
            </Center>
          </Grid.Col>
        </Grid>
      </Stack>
      {/* Middle component */}
      {loading ? (
        <Center>
          <Loader />
        </Center>
      ) : (
        getFilterContent()
      )}
      {/* Footer */}
      <Box className={classes.buttonWrapper}>
        <Flex align="center" justify="space-between">
          {menu !== 'sort' && (
            <ClearAllButton
              onClick={() => {
                handleClearButton();
                getMobileFilterTrackEvent(
                  Actions.SUB_FILTER_CLEAR_MOBILE,
                  Actions.BOOKINGS_FILTERS_MOBILE,
                  Actions.SCHEDULED_FILTER_CLEAR_MOBILE,
                  bookingsView,
                );
              }}
              filterComponent={currentInnerModalComponent}
            />
          )}
          <PebbleButtonSet
            btnVariant={PebbleButtonsEnum.PRIMARY}
            size="md"
            fullWidth={menu === 'sort'}
            onClick={() => {
              onClose();
              getMobileFilterTrackEvent(
                Actions.SUB_SHOWRESULTS_MOBILE,
                Actions.BOOKING_SHOWRESULTS_MOBILE,
                Actions.SCHEDULED_SHOWRESULTS_MOBILE,
                bookingsView,
              );
            }}
          >
            Show results
          </PebbleButtonSet>
        </Flex>
      </Box>
    </Drawer>
  );
};
