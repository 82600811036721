import { useState, useEffect, FC } from 'react';
import { Button, Text, Modal, useMantineTheme } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Auth } from '@pebble/common';
import { RegistrationError } from './RegistrationError';
import { trackAction, Actions } from 'utils/amplitude';
import { TrackableFormPasswordInput } from '../';
import classes from './RegistrationForm.module.scss';
import { PebbleCheckbox, PebbleTextInput } from 'components/common';

const TERMS_LINK = 'https://www.bookpebble.co.uk/provider-terms';
const SERVICE_AGREEMENT_LINK = 'https://www.bookpebble.co.uk/provider-service-agreement';

type FormValues = {
  email: string;
  password: string;
  confirmPassword: string;
  termsOfUse: boolean;
  serviceAgreement: boolean;
};

interface ILoginFormProps {
  onRegister(email: string): void;
}

export const RegistrationForm: FC<ILoginFormProps> = ({ onRegister }) => {
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const theme = useMantineTheme();

  const form = useForm<FormValues>({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      termsOfUse: false,
      serviceAgreement: false,
    },
    validate: {
      email: (value: string) => (/^\S+@\S+$/.test(value) ? null : 'Must be a valid email address'),
      password: (value: string) =>
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(value)
          ? null
          : 'Must be 8+ characters, mix of upper and lowercase letters and at least 1 number',
      confirmPassword: (confirmPassword: string, values: FormValues) =>
        confirmPassword === values?.password ? null : 'Passwords do not match',
      termsOfUse: (value: boolean) => (value === true ? null : 'Please tick this box to continue'),
      serviceAgreement: (value: boolean) =>
        value === true ? null : 'Please tick this box to continue',
    },
  });

  const handleSubmit = async (values: FormValues) => {
    trackAction(Actions.SUPPLIER_REGISTER_CTA);
    try {
      setIsSubmitting(true);
      await Auth.signUp(values.email, values.password);
      onRegister(values.email);
    } catch (e) {
      setShowErrorModal(true);
      form.setErrors({
        email: true,
        password: true,
        confirmPassword: true,
        termsOfUse: false,
        serviceAgreement: false,
      });
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (form.values.termsOfUse) {
      trackAction(Actions.SUPPLIER_REGISTER_TERMS);
    }
  }, [form.values.termsOfUse]);

  useEffect(() => {
    if (form.values.serviceAgreement) {
      trackAction(Actions.SUPPLIER_REGISTER_PASA);
    }
  }, [form.values.serviceAgreement]);

  return (
    <>
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <PebbleTextInput
          required
          label="Your e-mail"
          placeholder="your@email.com"
          radius="lg"
          size="lg"
          style={{
            margin: '24px 0',
          }}
          defaultInput
          action={Actions.SUPPLIER_REGISTER_EMAIL}
          {...form.getInputProps('email')}
        />
        <TrackableFormPasswordInput
          required
          label="Create a password (min. 8 characters)"
          size="lg"
          placeholder="Password"
          radius="lg"
          style={{
            margin: '24px 0',
          }}
          action={Actions.SUPPLIER_REGISTER_PASSWORD}
          {...form.getInputProps('password')}
        />
        <TrackableFormPasswordInput
          required
          label="Confirm password"
          size="lg"
          placeholder="Re-type password"
          radius="lg"
          style={{
            margin: '24px 0',
          }}
          action={Actions.SUPPLIER_REGISTER_PASSWORD_CONFIRM}
          {...form.getInputProps('confirmPassword')}
        />
        <PebbleCheckbox
          checked={false}
          label={
            <Text
              style={{
                fontSize: theme.fontSizes.sm,
                marginBottom: theme.spacing.sm,
              }}
            >
              I've read & I accept Pebble's{' '}
              <a href={TERMS_LINK} target="_blank" rel="noopener noreferrer">
                Terms of Use
              </a>
            </Text>
          }
          labelPosition="right"
          checkboxLabelOriginal
          required
          {...form.getInputProps('termsOfUse', { type: 'checkbox' })}
        />
        <PebbleCheckbox
          checked={false}
          label={
            <Text
              style={{
                fontSize: theme.fontSizes.sm,
              }}
            >
              I've read & I accept Pebble's{' '}
              <a href={SERVICE_AGREEMENT_LINK} target="_blank" rel="noopener noreferrer">
                Provider Service Agreement
              </a>
            </Text>
          }
          labelPosition="right"
          checkboxLabelOriginal
          required
          {...form.getInputProps('serviceAgreement', { type: 'checkbox' })}
        />
        <Button
          type="submit"
          className={classes.pinkButton}
          radius="lg"
          size="lg"
          loading={isSubmitting}
        >
          Register
        </Button>
      </form>
      <Modal opened={showErrorModal} onClose={() => setShowErrorModal(false)}>
        <RegistrationError />
      </Modal>
    </>
  );
};
