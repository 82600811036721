import { useState, FC } from 'react';
import { Button, Text, UnstyledButton } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Auth } from '@pebble/common';
import { Actions, trackAction } from 'utils/amplitude';
import { PebbleTextInput } from 'components/common';
import classes from './VerifyEmailForm.module.scss';

type FormValues = {
  verificationCode: string;
  email?: string;
};

interface IVerifyEmailFormProps {
  onVerify(): void;
  email?: string;
}

export const VerifyEmailForm: FC<IVerifyEmailFormProps> = ({ onVerify, email }) => {
  const [serverError, setServerError] = useState<string>('');
  const form = useForm({
    initialValues: {
      verificationCode: '',
      ...(!email && {
        email: '',
      }),
    },
    validate: {
      ...(!email && {
        email: (value: string | undefined) =>
          value && /^\S+@\S+$/.test(value) ? null : 'Must be a valid email address',
      }),
      verificationCode: (value: string) => (value.length === 6 ? null : 'Code invalid'),
    },
  });

  const handleResendCode = async () => {
    trackAction(Actions.SUPPLIER_VERIFY_RESEND);
    const emailToSendCodeTo = email || form.values.email || '';
    try {
      await Auth.resendVerificationCode(emailToSendCodeTo);
    } catch (e) {
      setServerError('Something went wrong. Please try again.');
    }
  };

  const handleSubmit = async (values: FormValues) => {
    trackAction(Actions.SUPPLIER_VERIFY_CTA);

    if (serverError) {
      setServerError('');
    }
    try {
      const emailToVerify = email || values.email || '';
      await Auth.verifySignup(emailToVerify, values.verificationCode);
      onVerify();
    } catch (e) {
      setServerError('The verification code provided is incorrect. Please try again');
      form.setErrors({
        verificationCode: true,
      });
    }
  };

  return (
    <form onSubmit={form.onSubmit((values) => handleSubmit(values as FormValues))}>
      {!email && (
        <PebbleTextInput
          required
          label="Your e-mail"
          placeholder="your@email.com"
          radius="lg"
          size="lg"
          style={{
            margin: '24px 0',
          }}
          defaultInput
          {...form.getInputProps('email')}
        />
      )}
      <PebbleTextInput
        required
        label="6 digit code"
        placeholder="Enter code"
        radius="lg"
        size="lg"
        style={{
          margin: '24px 0',
        }}
        defaultInput
        action={Actions.SUPPLIER_VERIFY_CODE}
        {...form.getInputProps('verificationCode')}
      />
      {serverError && (
        <Text size="sm" c="red" role="alert">
          {serverError}
        </Text>
      )}
      <UnstyledButton onClick={handleResendCode} className={classes.resendButton}>
        Resend verification code
      </UnstyledButton>
      <Button
        type="submit"
        classNames={{
          root: classes.verifyButton,
        }}
        radius="lg"
        size="lg"
      >
        Verify
      </Button>
    </form>
  );
};
