import { gql } from '@apollo/client';

const TrialBlockFailedPaymentsQuery = gql`
  query TrialBlockFailedPaymentsQuery($supplierId: UUID!) {
    trialBlockFailedPayments(supplierId: $supplierId) {
      id
      status
      createdAt
      activity {
        id
        name
        isOnline
        ageRange
        location {
          addressLine1
          addressLine2
          city
          postCode
        }
      }
      tickets {
        id
        attendees {
          id
          fullName
        }
        activityClass {
          id
          name
        }
        sessions {
          id
        }
        booking {
          id
        }
      }
      payment {
        id
        cardLast4
        cardBrand
        cardExpiry
        manualCaptureRetries
        manualCaptureLatestRetryAt
        scheduledAt
        retryAllowed
        amount
      }
      guardian {
        id
        fullName
      }
    }
  }
`;

export default TrialBlockFailedPaymentsQuery;
