import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { Center, Drawer, Menu, Stack, Text, useMantineTheme } from '@mantine/core';
import Image from 'next/image';
import { ActivityType } from 'interfaces';
import { ActivityStatusEnum } from 'enums';
import { LoadingCards } from 'components/LoadingCards';
import { ActivityCard } from 'components/pages/ScheduledActivities/components/ActivityCard';
import {
  buildFullAddressFromLocation,
  getDates,
  getTimes,
} from 'components/pages/ScheduledActivities/ScheduledActivities.utils';
import { isNewlyCreated } from 'utils/isNewlyCreated';
import { DeleteModal } from 'components/DeleteModal';
import { Actions, trackAction } from 'utils/amplitude';
import { useRouter } from 'next/router';
import { Capacity, Clone } from '@icons';
import { getQuickViewLink } from 'utils/getQuickViewLink/getQuickViewLink';
import classes from './CardsView.module.scss';
// eslint-disable-next-line import/no-extraneous-dependencies
import QRCode from 'qrcode';
import { useClipboard } from '@mantine/hooks';
import {
  ListPlus,
  Trash,
  Eye,
  PencilSimple,
  X,
  EnvelopeSimple,
  QrCode,
  Copy,
  CheckCircle,
} from '@phosphor-icons/react';
import { IntercomEvents, trackIntercomEvent } from '@utils';

interface ICardsViewProps {
  loading: boolean;
  activitiesBySupplier: ActivityType[];
  onDelete(): void;
  onClone(clonedActivityId: string): void;
  onStatusChange(activityStatus: ActivityStatusEnum, activityId: string): void;
  token: string;
  onInviteAttendee(mobileSelectedActivityId: string): void;
  handleActivitySelection(activity: ActivityType): void;
  setActivityToDelete: Dispatch<SetStateAction<string | null>>;
  activityToDelete: string | null;
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
  showDeleteModal: boolean;
}

export const CardsView: FC<ICardsViewProps> = (props) => {
  const router = useRouter();
  const theme = useMantineTheme();
  const {
    loading,
    activitiesBySupplier,
    onDelete,
    onClone,
    onStatusChange,
    token,
    onInviteAttendee,
    handleActivitySelection,
    showDeleteModal,
    setShowDeleteModal,
    activityToDelete,
    setActivityToDelete,
  } = props;
  const [showMobileDrawer, setShowMobileDrawer] = useState<boolean>(false); // uses the id of the activity to denote whether open or closed
  const [mobileSelectedActivityId, setMobileSelectedActivityId] = useState<string>('');
  const [copiedUrl, setCopiedUrl] = useState(false);

  const mobileSelectedActivity: ActivityType = useMemo(() => {
    return activitiesBySupplier?.find((act) => act.id === mobileSelectedActivityId);
  }, [mobileSelectedActivityId, activitiesBySupplier]) as ActivityType;

  const clipboard = useClipboard({ timeout: 1000 });

  const handleDeleteClick = () => {
    setActivityToDelete(mobileSelectedActivity.id);
    setShowDeleteModal(true);
    trackAction(Actions.SCHEDULED_DELETE);
  };

  const openMobileDrawer = (activityId: string) => {
    setMobileSelectedActivityId(activityId);
    setShowMobileDrawer(true);
  };

  const handleActivityNameClick = (activityId: string) => {
    const selectedActivity = activitiesBySupplier?.find(
      (act) => act.id === activityId,
    ) as ActivityType;
    handleActivitySelection(selectedActivity);
  };

  const handleDownloadQRCode = async () => {
    try {
      const qrURL = getQuickViewLink(mobileSelectedActivity);

      const dataUrl = await QRCode.toDataURL(qrURL, { width: 400 });

      const downloadLink = document.createElement('a');

      downloadLink.href = dataUrl;

      downloadLink.download = `${mobileSelectedActivity.name} QRCode.png`;

      document.body.appendChild(downloadLink);

      downloadLink.click();

      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error('Error generating QR code:', error);
    }
  };

  return (
    <>
      <Stack gap="sm">
        {loading ? (
          <LoadingCards />
        ) : activitiesBySupplier?.length ? (
          activitiesBySupplier?.map((activity: ActivityType) => (
            <ActivityCard
              key={activity.id}
              id={activity.id}
              name={activity.name}
              status={activity.status}
              maxDate={activity.maxDate}
              minDate={activity.minDate}
              dates={getDates(activity?.minDate, activity?.maxDate)}
              address={
                !activity.isOnline
                  ? buildFullAddressFromLocation(
                      ['addressLine1', 'addressLine2', 'city', 'postCode'],
                      activity?.location,
                    )
                  : null
              }
              times={getTimes(activity?.times)}
              bookingType={activity.bookingType}
              blockSubtype={activity.blockSubtype}
              activitySessions={activity?.classes.flatMap(
                (activityClass) => activityClass.sessions,
              )}
              isNewlyCreated={isNewlyCreated(activity.createdAt)}
              onMobileDrawerOpenClick={(id: string) => openMobileDrawer(id)}
              onCloneClick={onClone}
              onStatusChange={onStatusChange}
              subsSchedules={activity?.schedules}
              isOnline={activity.isOnline || false}
              bookingsInformation={activity.bookingsInformation}
              waitlistRequestCount={activity.waitlistRequestCount}
              handleActivityNameClick={handleActivityNameClick}
              ageRange={activity?.ageRange}
            />
          ))
        ) : (
          <Center
            style={{
              height: '40vh',
            }}
          >
            <Stack>
              <Center>
                <Image
                  src="/parent-with-child.png"
                  alt="Parent with child"
                  height={171}
                  width={238}
                />
              </Center>
              <Text fw={700} ta="center" size={'lg'}>
                Let’s get started — add your first activity here!
              </Text>
            </Stack>
          </Center>
        )}
      </Stack>

      <Drawer
        padding="sm"
        opened={showMobileDrawer}
        onClose={() => {
          setShowMobileDrawer(false);
          setMobileSelectedActivityId('');
        }}
        position="bottom"
        classNames={{
          root: classes.drawer,
          header: classes.drawerHeader,
          close: classes.drawerCloseButton,
          content: classes.drawerContent,
        }}
        title="Options"
        closeButtonProps={{
          icon: <X size={20} weight="bold" />,
        }}
      >
        <Menu classNames={{ item: classes.menuItem }}>
          <Menu.Item
            leftSection={<Capacity size={24} />}
            component="a"
            href={`/edit-activity/${mobileSelectedActivity?.id}?supplierId=${router.query.supplierId}&tab=capacity`}
            target="_blank"
            onClick={() => {
              trackAction(Actions.SCHEDULED_SESSIONS);
            }}
          >
            Adjust capacity
          </Menu.Item>

          <Menu.Item
            leftSection={<Clone size={24} />}
            onClick={() => {
              onClone(mobileSelectedActivityId);
              trackAction(Actions.SCHEDULED_CLONE);
              trackIntercomEvent(IntercomEvents.CLONE_ACTIVITY);
            }}
          >
            Clone
          </Menu.Item>

          <Menu.Item
            leftSection={<PencilSimple weight="fill" size={22} />}
            component="a"
            href={`/edit-activity/${mobileSelectedActivity?.id}?supplierId=${router.query.supplierId}`}
            target="_blank"
            onClick={() => {
              trackAction(Actions.SCHEDULED_EDIT);
            }}
          >
            Edit activity
          </Menu.Item>

          <Menu.Item
            leftSection={<EnvelopeSimple size={24} weight="fill" />}
            onClick={() => {
              setShowMobileDrawer(false);
              onInviteAttendee(mobileSelectedActivityId);
              trackAction(Actions.ENROL_CUSTOMERS_CTA);
            }}
          >
            Invite customers
          </Menu.Item>

          <Menu.Item
            leftSection={<ListPlus size={24} />}
            component="a"
            href={`/manage-waitlist/${mobileSelectedActivityId}?supplierId=${router.query.supplierId}`}
            onClick={() => trackAction(Actions.VIEW_WAITLIST)}
          >
            Waitlist
          </Menu.Item>

          <Menu.Item
            leftSection={<Eye weight="bold" size={24} className={classes.menuIcon} />}
            component="a"
            href={getQuickViewLink(mobileSelectedActivity)}
            target="_blank"
            onClick={() => {
              trackAction(Actions.SCHEDULED_VIEW);
            }}
          >
            View listing
          </Menu.Item>

          <Menu.Item
            leftSection={<QrCode weight="fill" size={24} className={classes.menuIcon} />}
            onClick={handleDownloadQRCode}
          >
            Get QR code
          </Menu.Item>

          <Menu.Item
            leftSection={
              copiedUrl ? (
                <CheckCircle size={24} weight="fill" className={classes.menuIcon} />
              ) : (
                <Copy size={24} weight="bold" className={classes.menuIcon} />
              )
            }
            onClick={() => {
              clipboard.copy(getQuickViewLink(mobileSelectedActivity));
              setCopiedUrl(true);
              setTimeout(() => {
                setShowMobileDrawer(false);
                setCopiedUrl(false);
              }, 2000);
            }}
          >
            {copiedUrl ? 'Copied!' : 'Copy URL'}
          </Menu.Item>

          <Menu.Item
            leftSection={<Trash weight="fill" size={22} />}
            onClick={() => {
              handleDeleteClick();
              setShowDeleteModal(true);
              trackAction(Actions.SCHEDULED_DELETE);
            }}
            style={{ color: theme.colors.red[8] }}
          >
            Delete activity
          </Menu.Item>
        </Menu>
      </Drawer>

      <DeleteModal
        afterDelete={() => {
          setShowMobileDrawer(false);
          setMobileSelectedActivityId('');
        }}
        token={token}
        open={showDeleteModal && !!activityToDelete}
        activityId={activityToDelete}
        onClose={() => {
          setActivityToDelete(null);
          setShowDeleteModal(false);
          onDelete();
        }}
        onCancel={() => {
          setActivityToDelete(null);
          setShowDeleteModal(false);
        }}
      />
    </>
  );
};
