import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isToday from 'dayjs/plugin/isToday';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { ActivityType, DynamicFilterOption, LocationType, StartEndTimeType } from 'interfaces';
import {
  ActivityBookingTypeEnum,
  ActivityStatusEnum,
  DaysOfWeekEnum,
  TermDateFilterEnum,
} from 'enums';
import { ActivitySession, ActivitySubscriptionSchedule } from 'types';
import { NextRouter } from 'next/router';
import { getQuickViewLink } from 'utils/getQuickViewLink/getQuickViewLink';
import QRCode from 'qrcode';
dayjs.extend(customParseFormat);
dayjs.extend(isToday);
dayjs.extend(advancedFormat);

export type AddressFieldsType = Array<keyof LocationType>;

export const buildFullAddressFromLocation = (
  addressFields: AddressFieldsType,
  location?: LocationType,
): string => {
  if (!location) {
    return 'Unknown';
  }

  return addressFields
    .reduce<string>((address: string, field: keyof LocationType) => {
      if (location[field]) {
        return address + `${location[field]}, `;
      }
      return address;
    }, '')
    .slice(0, -2); // Remove last space and comma
};

export const getDates = (minDate?: string, maxDate?: string, yearIncluded: boolean = true) => {
  if (!minDate && !maxDate) {
    return '-';
  }

  if (!maxDate) {
    return `Next: ${dayjs(minDate)?.format(yearIncluded ? 'Do MMM YYYY' : 'Do MMM')}`;
  }

  return `${dayjs(minDate)?.format('DD MMM')} - ${dayjs(maxDate)?.format(
    yearIncluded ? 'DD MMM YYYY' : 'DD MMM',
  )}`;
};

export const getTimes = (times: StartEndTimeType[] = []) => {
  if (!times || times.length === 0) {
    return '';
  }
  if (times && times.length > 1) {
    return 'Multiple times';
  }

  return `${dayjs(times[0].startTime, 'HH:mm:ss').format('HH:mm')} - ${dayjs(
    times[0].endTime,
    'HH:mm:ss',
  ).format('HH:mm')}`;
};

export const buildFiltersQueryVariables = (
  termDateFilter: TermDateFilterEnum | null,
  selectedActivityFilter: string | null,
  selectedVenueFilter: string | null,
  selectedActivityTagFilter: string[] | null,
  selectedCategoryFilters: string[] | null,
) => {
  return {
    ...(termDateFilter && {
      dateFilter: termDateFilter,
    }),
    ...(selectedActivityFilter !== 'All activities' && {
      activityId: selectedActivityFilter,
    }),
    ...(selectedVenueFilter !== 'All locations' && {
      venue: selectedVenueFilter,
    }),
    ...(selectedActivityTagFilter &&
      selectedActivityTagFilter.length > 0 && {
        activityTags: selectedActivityTagFilter,
      }),
    ...(selectedCategoryFilters &&
      selectedCategoryFilters.length > 0 && {
        activityCategories: selectedCategoryFilters,
      }),
  };
};

export interface ScheduledActivitiesFilters {
  venues: DynamicFilterOption[];
  activities: DynamicFilterOption[];
  activityTags: DynamicFilterOption[];
  categories: DynamicFilterOption[];
}

type RawFilter = {
  name: string;
  id?: string;
  value?: string;
};

export type RawFilters = {
  activities: RawFilter[];
  venues: RawFilter[];
  activityTags: RawFilter[];
  categories: RawFilter[];
};

export const toFilterObject = (data: RawFilter) => {
  return {
    label: data.name,
    value: data.id || data.value || '',
  };
};

export const getScheduledActivitiesFilters = (filters: RawFilters): ScheduledActivitiesFilters => {
  return {
    activities: filters.activities.map(toFilterObject),
    venues: filters.venues.map(toFilterObject),
    activityTags: filters.activityTags.map(toFilterObject),
    categories: filters.categories.map(toFilterObject),
  };
};

export const getStringCapitalized = (string?: string) => {
  if (!string) {
    return '';
  }
  const lowerCaseString = string?.toLowerCase();
  return lowerCaseString[0].toUpperCase() + lowerCaseString.substring(1);
};
export const getActivityStatus = (activityStatus: ActivityStatusEnum): boolean =>
  activityStatus === ActivityStatusEnum.PUBLISHED;

export const isActivityInPast = (activityMaxDate?: string) => {
  if (!activityMaxDate) {
    return true;
  }
  return dayjs().isAfter(activityMaxDate, 'date');
};

export const getActivityType = (
  bookingType: string,
  blockSubtype: string | null,
  sessions: ActivitySession[],
) => {
  if (bookingType === ActivityBookingTypeEnum.INSTANT) {
    if (blockSubtype === null) {
      return 'Individual';
    } else if (blockSubtype !== null && sessions?.length === 0) {
      return 'Block';
    }
    return 'Block & Individual';
  } else {
    return 'Subscription';
  }
};

export const getSubsTimes = (schedules: ActivitySubscriptionSchedule[] | undefined) => {
  if (!schedules) {
    return;
  }

  if (schedules.length === 1) {
    return `${schedules?.[0].startTime} - ${schedules?.[0].endTime}`;
  }

  return 'Multiple times';
};

export const getAbbreviatedDaysString = (days?: DaysOfWeekEnum[]) => {
  if (!days) {
    return;
  }
  const dayAbbreviations: { [key in DaysOfWeekEnum]: string } = {
    [DaysOfWeekEnum.Monday]: 'Mo',
    [DaysOfWeekEnum.Tuesday]: 'Tu',
    [DaysOfWeekEnum.Wednesday]: 'We',
    [DaysOfWeekEnum.Thursday]: 'Th',
    [DaysOfWeekEnum.Friday]: 'Fr',
    [DaysOfWeekEnum.Saturday]: 'Sa',
    [DaysOfWeekEnum.Sunday]: 'Su',
  };

  return days.map((day) => dayAbbreviations[day]).join(', ');
};

export const getWeekdayLabel = (weekday?: DaysOfWeekEnum) => {
  if (!weekday) {
    return;
  } else {
    return weekday.charAt(0).toUpperCase() + weekday.slice(1).toLowerCase();
  }
};

export const handleEditClick = (router: NextRouter, id: string | undefined) => {
  router.push(`/edit-activity/${id}?supplierId=${router.query.supplierId}`);
};

export const handleCapacityClick = (router: NextRouter, id: string | undefined) => {
  router.push(`/edit-activity/${id}?supplierId=${router.query.supplierId}&tab=capacity`);
};

export const handleDownloadQRCode = async (selectedActivity: ActivityType | null) => {
  try {
    const qrURL = getQuickViewLink(selectedActivity);

    const dataUrl = await QRCode.toDataURL(qrURL, { width: 400 });

    const downloadLink = document.createElement('a');

    downloadLink.href = dataUrl;

    downloadLink.download = `${selectedActivity?.name} QRCode.png`;

    document.body.appendChild(downloadLink);

    downloadLink.click();

    document.body.removeChild(downloadLink);
  } catch (error) {
    console.error('Error generating QR code:', error);
  }
};
