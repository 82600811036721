import { gql } from '@apollo/client';

const calculateBlockTrialChange = gql`
  mutation calculateBlockTrialChange($input: CalculateChangeBlockTrialsDatesInput!) {
    calculateChangeBlockTrialDatesAmount(input: $input) {
      originalAmount
      finalAmount
      discount
      paymentScheduledAt
    }
  }
`;

export default calculateBlockTrialChange;
