import { useState, FC } from 'react';
import { Button, Text, PasswordInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Auth } from '@pebble/common';
import classes from './ResetPassword.module.scss';
import { PebbleTextInput } from 'components/common';

type FormValues = {
  verificationCode: string;
  password: string;
  confirmPassword: string;
};

interface IResetPasswordFormProps {
  onReset(): void;
  email?: string;
}

export const ResetPasswordForm: FC<IResetPasswordFormProps> = ({ onReset, email = '' }) => {
  const [serverError, setServerError] = useState<string>('');
  const form = useForm<FormValues>({
    initialValues: {
      verificationCode: '',
      password: '',
      confirmPassword: '',
    },
    validate: {
      verificationCode: (value: string) => (value.length === 6 ? null : 'Code invalid'),
      password: (value: string) =>
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(value)
          ? null
          : 'Must be 8+ characters, mix of upper and lowercase letters and at least 1 number',
      confirmPassword: (confirmPassword: string, values: FormValues) =>
        confirmPassword === values?.password ? null : 'Passwords do not match',
    },
  });

  const handleSubmit = async (values: FormValues) => {
    if (serverError) {
      setServerError('');
    }
    try {
      await Auth.forgotPasswordSubmit(values.verificationCode, email, values.password);
      onReset();
    } catch (e) {
      setServerError('Something went wrong. Please try again');
      form.setErrors({
        verificationCode: true,
        password: false,
        confirmPassword: false,
      });
    }
  };
  return (
    <form onSubmit={form.onSubmit((values) => handleSubmit(values as FormValues))}>
      <PebbleTextInput
        required
        label="6 digit code"
        placeholder="Enter code"
        radius="lg"
        size="lg"
        style={{
          margin: '24px 0',
        }}
        defaultInput
        {...form.getInputProps('verificationCode')}
      />
      {serverError && (
        <Text size="sm" color="red" role="alert">
          {serverError}
        </Text>
      )}
      <PasswordInput
        required
        label="New password"
        size="lg"
        placeholder="Password"
        {...form.getInputProps('password')}
        radius="lg"
        style={{
          margin: '24px 0',
        }}
        autoComplete="off"
      />
      <PasswordInput
        required
        label="Enter new password again"
        size="lg"
        placeholder="Re-type password"
        {...form.getInputProps('confirmPassword')}
        radius="lg"
        style={{
          margin: '24px 0',
        }}
        autoComplete="off"
      />
      <Button
        type="submit"
        className={classes.pinkButton}
        style={{ width: '100%', margin: '12px 0 36px' }}
        radius="lg"
        size="lg"
      >
        Change password
      </Button>
    </form>
  );
};
