import { gql } from '@apollo/client';

export const QuestionBankQuery = gql`
  query QuestionBankQuery($supplierId: UUID!) {
    questionBank(supplierId: $supplierId) {
      id
      title
      shortTitle
      type
      isPerAttendee
      isDefault
      choices
    }
  }
`;

export default QuestionBankQuery;
