import { Anchor, Card, Divider, Flex, Stack, Text } from '@mantine/core';
import classes from './ActivityTagsFilter.module.scss';
import { PebbleCheckbox, PebbleCheckboxGroup } from 'components/common';
import { DynamicFilterOption } from 'interfaces';
import classNames from 'classnames';
import { Fragment } from 'react';
import { Actions, trackAction } from 'utils/amplitude';

interface IActivityTagsFilter {
  setSelectedTags?(val: string[]): void;
  selectedTags?: string[] | null;
  tagsFilters?: DynamicFilterOption[];
}

const ActivityTagsFilter: React.FC<IActivityTagsFilter> = ({
  setSelectedTags,
  selectedTags,
  tagsFilters,
}) => {
  if (!setSelectedTags || !selectedTags || tagsFilters?.length === 0) {
    return;
  }

  return (
    <Card className={classes.filterCard}>
      <Stack>
        <Flex justify={'space-between'}>
          <Text fw={700} size="18px">
            Activity tags
          </Text>
          <Anchor
            component="button"
            onClick={() => {
              setSelectedTags(
                tagsFilters?.map((option: DynamicFilterOption) => option.value || option.label) ||
                  [],
              );
            }}
            style={{
              fontSize: '16px',
              fontWeight: 700,
              color: 'var(--mantine-color-blue-6)',
              textDecoration: 'underline',
            }}
          >
            Select all
          </Anchor>
        </Flex>
        <PebbleCheckboxGroup value={selectedTags} onChange={setSelectedTags}>
          {tagsFilters?.map((option: DynamicFilterOption) => {
            return (
              <Fragment key={option.value}>
                <PebbleCheckbox
                  value={option.value || option.label}
                  label={option.label}
                  classNames={{
                    root: classes.checkboxRoot,
                    icon: classNames(classes.checkboxIcon, {}),
                  }}
                  onClick={(event) => {
                    if (event.currentTarget.checked) {
                      trackAction(Actions.SUB_DAY_FILTER_MOBILE);
                    }
                  }}
                  fullWidth
                />
                <Divider c="#F1F1F2" />
              </Fragment>
            );
          })}
        </PebbleCheckboxGroup>
      </Stack>
    </Card>
  );
};

export default ActivityTagsFilter;
