import { useState, FC } from 'react';
import { Button, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Auth } from '@pebble/common';
import classes from './ResetPassword.module.scss';
import { PebbleTextInput } from 'components/common';

type FormValues = {
  email: string;
};

enum SubmitStatus {
  DEFAULT,
  SUBMITTING,
  ERROR,
}

interface ISendInstructionsFormProps {
  onSend(email: string): void;
}

export const SendInstructionsForm: FC<ISendInstructionsFormProps> = ({ onSend }) => {
  const [status, setStatus] = useState<SubmitStatus>(SubmitStatus.DEFAULT);

  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: (value: string) => (/^\S+@\S+$/.test(value) ? null : 'Must be a valid email address'),
    },
  });

  const handleSubmit = async (values: FormValues) => {
    setStatus(SubmitStatus.SUBMITTING);
    try {
      await Auth.forgotPasswordInitiate(values.email || '');
      onSend(values.email || '');
    } catch (e) {
      setStatus(SubmitStatus.ERROR);
      form.setErrors({
        email: true,
      });
    }
  };
  return (
    <form onSubmit={form.onSubmit((values) => handleSubmit(values as FormValues))}>
      <PebbleTextInput
        required
        label="Your e-mail"
        placeholder="your@email.com"
        radius="lg"
        size="lg"
        style={{
          margin: '24px 0',
        }}
        defaultInput
        {...form.getInputProps('email')}
      />
      {status === SubmitStatus.ERROR && (
        <Text size="sm" color="red" role="alert">
          Something went wrong. Please try again
        </Text>
      )}
      <Button
        type="submit"
        className={classes.pinkButton}
        style={{ width: '100%', margin: '12px 0 36px' }}
        radius="lg"
        size="lg"
        disabled={status === SubmitStatus.SUBMITTING}
      >
        Send instructions
      </Button>
    </form>
  );
};
