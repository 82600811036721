import { gql } from '@apollo/client';

const CreateActivityAttendeeNote = gql`
  mutation createActivityAttendeeNote(
    $activityId: UUID!
    $activityClassId: UUID
    $attendeeId: UUID!
    $text: String!
  ) {
    createActivityAttendeeNote(
      activityId: $activityId
      activityClassId: $activityClassId
      attendeeId: $attendeeId
      text: $text
    ) {
      id
      createdAt
      isActioned
    }
  }
`;

export default CreateActivityAttendeeNote;
