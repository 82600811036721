import { Box, Button, Checkbox, Collapse, Stack, useMantineTheme } from '@mantine/core';
import { useClickOutside, useDisclosure, useMediaQuery } from '@mantine/hooks';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import MultiRef from 'react-multi-ref';
import classes from './MultiselectFilterControl.module.scss';
import classNames from 'classnames';
import { DynamicFilterOption } from 'interfaces';
import { Actions, trackAction } from 'utils/amplitude';
import { CaretDown } from '@phosphor-icons/react';

interface IMultiselectFilterControlProps {
  leftSectionIcon: JSX.Element | null;
  selectedFilters: string[];
  setSelectedFilters: Dispatch<SetStateAction<string[]>>;
  availableFilters: DynamicFilterOption[];
  defaultLabel: string;
  action?: Actions;
  activityTags?: boolean;
}

export const MultiselectFilterControl: React.FC<IMultiselectFilterControlProps> = ({
  leftSectionIcon,
  selectedFilters,
  setSelectedFilters,
  availableFilters,
  defaultLabel,
  action,
  activityTags = false,
}) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);
  const [checkboxRefs] = useState(() => new MultiRef<number, HTMLInputElement>());
  const [dropdownOpened, { toggle }] = useDisclosure(false);
  const isIndeterminate = activityTags ? false : selectedFilters.length === 0;

  const ref = useClickOutside(() => {
    if (dropdownOpened) {
      toggle();
    }
    return;
  });

  useEffect(() => {
    if (isIndeterminate) {
      checkboxRefs.map.forEach((checkbox) => {
        checkbox.indeterminate = true;
      });
    }
  }, [isIndeterminate, checkboxRefs]);

  const getAppliedFilterLabel = (): string => {
    if (selectedFilters.length > 1) {
      return `${selectedFilters.length} filters`;
    } else if (selectedFilters.length === 1) {
      const selectedValue = availableFilters.find((option) => {
        return option.value === selectedFilters[0];
      });
      return selectedValue?.label || '1 filter';
    } else {
      return defaultLabel;
    }
  };

  return (
    <Box ref={ref} style={{ position: 'relative' }} w={isMobile ? '135px' : 'auto'}>
      <Button
        onClick={() => {
          toggle();
          if (action) {
            trackAction(action);
          }
        }}
        leftSection={leftSectionIcon}
        rightSection={<CaretDown weight="bold" size={16} />}
        classNames={{
          root: classNames(classes.filterButton, {
            [classes.filterSelected]: selectedFilters.length > 0,
            [classes.dataExpanded]: dropdownOpened,
          }),
          inner: classes.filterButtonInner,
          label: classes.filterButtonLabel,
          section: classNames({
            [classes.buttonIconRotate]: dropdownOpened,
          }),
        }}
      >
        {getAppliedFilterLabel()}
      </Button>

      <Collapse in={dropdownOpened} className={classes.floatingDropdown}>
        <Stack className={classes.checkboxesWrapper}>
          <Checkbox
            checked={selectedFilters.length === availableFilters.length}
            label={isIndeterminate ? defaultLabel : 'Select all'}
            labelPosition="left"
            indeterminate={isIndeterminate}
            onChange={() => {
              if (activityTags) {
                setSelectedFilters((prev) => {
                  if (prev.length === availableFilters.length) {
                    return [];
                  }
                  return availableFilters.map((option) => option.value || option.label);
                });
              } else {
                setSelectedFilters([]);
              }
            }}
            classNames={{
              input: classNames(classes.checkbox, {
                [classes.checkboxIndeterminate]: isIndeterminate,
              }),
              root: classes.checkboxRoot,
              body: classNames(classes.checkboxBody, {
                [classes.checkboxBodyIndeterminate]: isIndeterminate,
              }),
              label: classes.checkboxLabel,
              icon: classes.checkboxIcon,
            }}
            ref={checkboxRefs.ref(0)}
          />
          <Checkbox.Group value={selectedFilters} onChange={setSelectedFilters}>
            {availableFilters.map((option, index) => (
              <Checkbox
                key={option.value}
                value={option.value || option.label}
                label={option.label}
                labelPosition="left"
                indeterminate={isIndeterminate}
                classNames={{
                  input: classNames(classes.checkbox, {
                    [classes.checkboxIndeterminate]: isIndeterminate,
                  }),
                  root: classes.checkboxRoot,
                  body: classes.checkboxBody,
                  label: classes.checkboxLabel,
                  icon: classes.checkboxIcon,
                }}
                ref={checkboxRefs.ref(index + 1)}
              />
            ))}
          </Checkbox.Group>
        </Stack>
      </Collapse>
    </Box>
  );
};
