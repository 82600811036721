import { FC } from 'react';
import { SegmentedControl, Text, Stack } from '@mantine/core';
import { BookingsSortByEnum, ScheduledActivitySortByEnum } from 'enums';
import classes from './SortMenu.module.scss';
import classNames from 'classnames';

export interface ISortMenu {
  activeSortKey: BookingsSortByEnum | ScheduledActivitySortByEnum;
  onSortChange(key: string): void;
  ascKey: BookingsSortByEnum | ScheduledActivitySortByEnum;
  descKey: BookingsSortByEnum | ScheduledActivitySortByEnum;
}

export const SortMenu: FC<ISortMenu> = ({ activeSortKey, onSortChange, ascKey, descKey }) => {
  return (
    <Stack>
      <Text
        className={classNames(classes.label, {
          [classes.labelActive]: activeSortKey === ascKey || activeSortKey === descKey,
        })}
      >
        Date
      </Text>
      <SegmentedControl
        transitionDuration={0}
        radius="lg"
        classNames={{
          label: classes.controlLabel,
          control: classes.control,
        }}
        onChange={onSortChange}
        value={activeSortKey}
        data={[
          {
            label: 'Earliest',
            value: descKey as string,
          },
          {
            label: 'Latest',
            value: ascKey as string,
          },
        ]}
      />
    </Stack>
  );
};
